import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '@icons/wolfkit-light/clock-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { useProfileInfo } from '@entities/profile';
import { useGetProfilePortfoliosQuery } from '@shared/api/portfolio';
import { useAppSelector } from '@store/Hooks';
import { shouldFilterByAssessmentPassed } from '@entities/portfolio';
import ProfileTabLabel from './ProfileTabLabel';
const PortfolioIconSized = () => (_jsx(Icon, { size: 16, IconComponent: ClockIcon }));
const ClosedPortfoliosTabLabel = ({ profileNickname = undefined, isSelected = false, }) => {
    const { t } = useTranslation();
    const { profile } = useProfileInfo(profileNickname);
    const { user } = useAppSelector(state => state.user);
    const { data, isLoading, isError, } = useGetProfilePortfoliosQuery({
        profileId: (profile === null || profile === void 0 ? void 0 : profile.id) || '',
        assessmentPassed: shouldFilterByAssessmentPassed({
            portfolioProfileId: profile === null || profile === void 0 ? void 0 : profile.id,
            currentUser: user,
            isVerifiedTrader: profile === null || profile === void 0 ? void 0 : profile.isVerifiedTrader,
        }),
    });
    const amount = !isError && !isLoading && data ? String(data.length) : '';
    return (_jsx(ProfileTabLabel, { IconComponent: PortfolioIconSized, text: t('profile.tabs.closed_portfolios', { ns: 'translation' }), amount: amount, selected: isSelected }));
};
export default ClosedPortfoliosTabLabel;
