import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { ReactComponent as XIcon } from '@icons/socials/mono/x-mono.svg';
import Icon from '@shared/ui/icons/Icon';
import { ContainerRow } from '@src/components/styled';
import Copyrights from './components/Copyrights';
import LinkButton from './components/LinkButton';
const Container = styled.footer(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    boxSizing: 'border-box',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '18px 30px 24px 30px',
    gap: '40px',
}));
const IconButtonStyled = styled(IconButton)((props) => ({
    width: 24,
    height: 24,
    color: props.theme.palette.primary.main,
    backgroundColor: props.theme.palette.primary.light,
    '&:hover': {
        backgroundColor: props.theme.customColors.shades.primaryMidLight,
    },
    '&:active': {
        backgroundColor: props.theme.customColors.shades.primaryMidLight,
        boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.2)',
    },
}));
const LinksContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: 30,
}));
const Footer = () => {
    const { t } = useTranslation();
    return (_jsxs(Container, { children: [_jsx("a", { target: '_blank', rel: 'noopener noreferrer', 
                // TODO: discuss where to store the url instead of hardcoding it
                href: 'https://twitter.com/endrex_net', children: _jsx(IconButtonStyled, { children: _jsx(Icon, { size: 16, IconComponent: XIcon }) }) }), _jsxs(LinksContainer, { children: [_jsx(LinkButton, { children: t('links.privacy_policy', { ns: 'common' }) }), _jsx(LinkButton, { children: t('links.terms_and_conditions', { ns: 'common' }) })] }), _jsx(Copyrights, {})] }));
};
export default Footer;
