import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Tabs from '@shared/ui/navigation/Tabs';
import { ContainerColumn } from '@components/styled';
import { useProfileInfo } from '@entities/profile';
import useProfileNavigation, { matchPathToProfileTab } from '@features/profile/profile-navigation';
import { getClientTabs, getTraderTabs } from './utils';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const ProfileTabsWidget = ({ className = undefined, profileNickname = undefined, }) => {
    const theme = useTheme();
    const location = useLocation();
    const { navigateToProfileTab } = useProfileNavigation();
    const currentTab = useMemo(() => matchPathToProfileTab(location.pathname), [location]);
    const { profile } = useProfileInfo(profileNickname);
    const tabs = useMemo(() => {
        if (!profile || !currentTab) {
            return [];
        }
        if (profile.type === 'CLIENT') {
            return getClientTabs({ profileNickname, selectedTab: currentTab });
        }
        return getTraderTabs({ profileNickname, selectedTab: currentTab });
    }, [profileNickname, profile, currentTab]);
    const onTabChange = (selectedTab) => {
        if (profile) {
            navigateToProfileTab(selectedTab, { params: { nickname: profile.username } });
        }
    };
    useEffect(() => {
        if (profile && !currentTab) {
            navigateToProfileTab('portfolios', {
                params: { nickname: profile.username },
                replace: true,
            });
        }
    }, [
        profile,
        currentTab,
        navigateToProfileTab,
    ]);
    return (_jsx(Container, { className: className, children: _jsx(Tabs, { tabs: tabs, activeTab: currentTab, onChange: onTabChange, tabSize: 'small', tabsGap: theme.spacing_sizes.xl, removePadding: true, containerBorderBottom: 'none' }) }));
};
export default ProfileTabsWidget;
