import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopiersIcon } from '@icons/custom/copy-light.svg';
import Icon from '@shared/ui/icons/Icon';
import ProfileTabLabel from './ProfileTabLabel';
const CopiersIconSized = () => (_jsx(Icon, { size: 16, IconComponent: CopiersIcon }));
const CopiersTabLabel = ({ isSelected = false, }) => {
    const { t } = useTranslation();
    return (_jsx(ProfileTabLabel, { IconComponent: CopiersIconSized, text: t('profile.tabs.copiers'), amount: '0', selected: isSelected }));
};
export default CopiersTabLabel;
