var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
const LinkButtonStyled = styled.a((props) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 10px',
    gap: '6px',
    borderRadius: '4px',
    cursor: 'pointer',
    userSelect: 'none',
    color: props.theme.palette.primary.main,
    backgroundColor: 'transparent',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    '&:hover': {
        backgroundColor: '#E6EDFE',
    },
    '&:active': {
        boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
    },
}));
const LinkButton = (_a) => {
    var props = __rest(_a, []);
    return (_jsx(LinkButtonStyled
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props)));
};
export default LinkButton;
