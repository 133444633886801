import { jsx as _jsx } from "react/jsx-runtime";
import PortfoliosTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/PortfoliosTabLabel';
import ActivityTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/ActivityTabLabel';
import CopiersTabLabel from '@widgets/ProfileTabsWidget/ui/tabs/CopiersTabLabel';
import ClosedPortfoliosTabLabel from './tabs/ClosedPortfoliosTabLabel';
const getClientTabs = ({ profileNickname = undefined, selectedTab, }) => ([
    {
        value: 'portfolios',
        label: (_jsx(PortfoliosTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'portfolios', isClientProfile: true })),
    },
    // NOTE: hidden from display until we add this functionality
    // {
    //     value: 'followers' as const,
    //     label: (
    //         <FollowersTabLabel
    //             profileNickname={profileNickname}
    //             isSelected={selectedTab === 'followers'}
    //         />
    //     ),
    // },
    // {
    //     value: 'followings' as const,
    //     label: (
    //         <FollowingsTabLabel
    //             profileNickname={profileNickname}
    //             isSelected={selectedTab === 'followings'}
    //         />
    //     ),
    // },
    {
        value: 'activity',
        label: (_jsx(ActivityTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'activity' })),
    },
]);
const getTraderTabs = ({ profileNickname = undefined, selectedTab, }) => ([
    {
        value: 'portfolios',
        label: (_jsx(PortfoliosTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'portfolios' })),
    },
    {
        value: 'closed_portfolios',
        label: (_jsx(ClosedPortfoliosTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'closed_portfolios' })),
    },
    // NOTE: hidden from display until we add this functionality
    // {
    //     value: 'followers' as const,
    //     label: (
    //         <FollowersTabLabel
    //             profileNickname={profileNickname}
    //             isSelected={selectedTab === 'followers'}
    //         />
    //     ),
    // },
    // {
    //     value: 'followings' as const,
    //     label: (
    //         <FollowingsTabLabel
    //             profileNickname={profileNickname}
    //             isSelected={selectedTab === 'followings'}
    //         />
    //     ),
    // },
    {
        value: 'copiers',
        label: (_jsx(CopiersTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'copiers' })),
    },
    {
        value: 'activity',
        label: (_jsx(ActivityTabLabel, { profileNickname: profileNickname, isSelected: selectedTab === 'activity' })),
    },
]);
export { getClientTabs, getTraderTabs, };
